@use "../../_base";

.Leystung {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 26rem;
  border-radius: 10px;
  margin: 1rem;
  background-color: white;
  padding: 0.2rem;
  transition: ease-out 0.4s height;
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));
  cursor: pointer;

  &:hover .img__container {
    height: 12rem;
  }

  p {
    padding: 1rem;
    color: base.$grey;
  }

  .img__container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 15rem;
    border-radius: 10px;
    transition: ease-out 0.2s height;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }
    h3 {
      position: absolute;
      padding: 1rem;
      color: base.$dark-grey;
      font-weight: 500;
      border-radius: 0px 10px 0px 0px;
      background-color: rgba($color: #ffffff, $alpha: 1);
    }
  }
}

@media screen and (max-width: 1500px) {
  .Leystung {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 28rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: white;
    padding: 0.2rem;
    transition: ease-out 0.4s height;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));
    cursor: pointer;

    &:hover .img__container {
      height: 12rem;
    }

    p {
      padding: 1rem;
      color: base.$grey;
    }

    .img__container {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 15rem;
      border-radius: 10px;
      transition: ease-out 0.2s height;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
      }
      h3 {
        position: absolute;
        padding: 1rem;
        color: base.$dark-grey;
        font-weight: 500;
        border-radius: 0px 10px 0px 0px;
        background-color: rgba($color: #ffffff, $alpha: 1);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .Leystung {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 30rem;
    border-radius: 10px;
    margin: 1rem auto;
    background-color: white;
    padding: 0.2rem;
    transition: ease-out 0.4s height;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));
    cursor: pointer;

    &:hover .img__container {
      height: 15rem;
    }

    p {
      padding: 1rem;
      color: base.$grey;
    }

    .img__container {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 15rem;
      border-radius: 10px;
      transition: ease-out 0.2s height;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
      }
      h3 {
        position: absolute;
        padding: 1rem;
        color: base.$dark-grey;
        font-weight: 500;
        border-radius: 0px 10px 0px 0px;
        background-color: rgba($color: #ffffff, $alpha: 1);
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .Leystung {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 30rem;
    border-radius: 10px;
    margin: 1rem auto;
    background-color: white;
    padding: 0.2rem;
    transition: ease-out 0.4s height;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));
    cursor: pointer;

    &:hover .img__container {
      height: 15rem;
    }

    p {
      padding: 1rem;
      color: base.$grey;
    }

    .img__container {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 15rem;
      border-radius: 10px;
      transition: ease-out 0.2s height;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
      }
      h3 {
        position: absolute;
        padding: 1rem;
        color: base.$dark-grey;
        font-weight: 500;
        border-radius: 0px 10px 0px 0px;
        background-color: rgba($color: #ffffff, $alpha: 1);
      }
    }
  }
}
