@use "../../_base";
header {
  display: flex;
  position: fixed;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: ease-out 0.2s;
  background-color: base.$primary-color;
  color: base.$secondary-text;
  z-index: 999;

  .emergency {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    background-color: #337996;
    padding: 0.25rem;

    .notfall {
      color: white;
    }
  }

  .header__section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    .logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0.5rem 1rem;

      span {
        font-size: 1.2rem;
        margin: 0 1rem;
        color: base.$secondary-text;
      }

      img {
        height: 4rem;
      }
    }

    nav {
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 0.5rem 1rem;

          .contact {
            border-radius: 5px;
            padding: 0.5rem 1rem;
            color: base.$secondary-text;
            transition: ease-out 0.1s;
            background-color: rgba($color: #ffffff, $alpha: 0.2);

            &:hover {
              background-color: white;
              color: base.$primary-text;
            }
          }
        }
      }
    }
  }
}

.fixedHeader {
  position: fixed;
  width: 100%;
  z-index: 100;
  //background-color: rgba(0, 0, 0, 0.8);
  filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
  //backdrop-filter: blur(0.5rem);
}

@media screen and (max-width: 750px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .header__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoContainer {
        display: flex;
        margin: 0.5rem 0;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .header__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoContainer {
        display: flex;
        margin: 0.5rem 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .header__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoContainer {
        display: flex;
        margin: 0.5rem 0;
      }
    }
  }
}
