@use "../../_base";

.Rohrreinigung {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin: 1rem;
  background-color: white;
  transition: ease-out 0.4s height;
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));
  cursor: pointer;

  &:hover .bg {
    transform: scale(1.05);
  }

  .img__container {
    display: flex;
    flex-direction: column;
    height: 25rem;
    border-radius: 10px;
    overflow: hidden;
    .overlay {
      position: absolute;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      background: linear-gradient(0deg, #ffc400 0%, rgba(0, 0, 0, 0) 50% 100%);
      border-radius: 10px;
      height: 100%;
      padding: 1.5rem;
      overflow: hidden;
      z-index: 2;

      .logo {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        object-position: center;
      }

      .info {
        display: flex;
        flex-direction: column;
        h3 {
          padding: 0.5rem;
          font-size: 3rem;
          color: white;
          font-weight: 500;
          border-radius: 0px 10px 0px 0px;
        }

        p {
          padding: 0.5rem;
          font-size: 1.5rem;
          color: white;
          filter: drop-shadow(0 1px 1px #000000);
        }
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      transition: 0.2s ease-out transform;
    }
  }
}

@media screen and (max-width: 500px) {
  .Rohrreinigung {
    display: flex;
    flex-direction: column;
    width: 90%;
    border-radius: 10px;
    margin: 1rem auto;
    height: 30rem;
    background-color: white;
    transition: ease-out 0.4s height;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));
    cursor: pointer;

    &:hover .bg {
      transform: scale(1.05);
    }

    .img__container {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      margin: 0;
      .overlay {
        position: absolute;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background: linear-gradient(
          0deg,
          #ffc400 0%,
          rgba(0, 0, 0, 0) 50% 100%
        );
        border-radius: 10px;
        height: 100%;
        margin: 0;
        padding: 1rem 0;
        overflow: hidden;
        z-index: 2;

        .logo {
          width: 5rem;
          height: 5rem;
          margin: 0.5rem;
          object-fit: cover;
          object-position: center;
          filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.5));
        }

        .info {
          display: flex;
          flex-direction: column;
          h3 {
            padding: 0.5rem;
            font-size: 3rem;
            color: white;
            font-weight: 500;
            border-radius: 0px 10px 0px 0px;
            filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.5));
          }

          p {
            padding: 0.5rem;
            font-size: 1rem;
            color: white;
            filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.5));
          }
        }
      }
      .bg {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        margin: auto;
        transition: 0.2s ease-out transform;
      }
    }
  }
}
