@use "../../_base";

.NotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    background-color: white;

    span {
        font-size: 2rem;
    }

    .Number {
        font-size: 10rem;
        color: base.$primary-color;
    }
}