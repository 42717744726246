@use "../../_base";

.DataPrivacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: base.$primary-text;
  background-color: base.$secondary-bg;

  .section__container {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 5rem 2rem;
    margin: 0;
  }

  h1 {
    color: base.$primary-color;
  }

  h2 {
    color: base.$primary-color;
    margin-top: 2rem !important;
  }

  span {
    margin: 0.5rem 0;
    width: 90%;
    text-align: justify;
  }
}

@media screen and (max-width: 500px) {
  .DataPrivacy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: base.$primary-text;
    width: 100%;
    background-color: base.$secondary-bg;

    .section__container {
      display: flex;
      flex-direction: column;
      padding: 5rem 2rem;
      width: 100%;
      margin: 0;
    }

    h1 {
      color: base.$primary-color;
    }

    h2 {
      color: base.$primary-color;
      margin-top: 2rem !important;
      font-size: 1.1rem;
    }

    span {
      margin: 0.5rem 0;
      width: 100%;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 390px) {
  .DataPrivacy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: base.$primary-text;
    width: 100%;
    background-color: base.$secondary-bg;

    .section__container {
      display: flex;
      flex-direction: column;
      padding: 5rem 2rem;
      width: 100%;
      margin: 0;
    }

    h1 {
      color: base.$primary-color;
    }

    h2 {
      color: base.$primary-color;
      margin-top: 2rem !important;
      font-size: 1.1rem;
    }

    span {
      margin: 0.5rem 0;
      width: 100%;
      text-align: justify;
    }
  }
}
