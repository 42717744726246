@use "../../_base";

button {
  cursor: pointer;
  padding: 0.7rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: ease-out 0.1s;
  &.Primary {
    color: base.$secondary-text;
    background-color: base.$primary-color;
    border: 2px solid transparent;

    &:hover {
      background-color: base.$primary-color-hover;
    }
  }

  &.Secondary {
    background-color: transparent;
    border: 2px solid base.$primary-color;
    color: base.$secondary-text;

    &:hover {
        color: base.$primary-color;
        background-color: white;
        border: 2px solid white;
    }
  }
}
