@use "../../_base";

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: base.$secondary-text;
  background-color: base.$primary-bg;
  padding: 3rem;

  .footer__menu {
    display: flex;
    flex-direction: column;
    span {
      width: 100%;
      height: 2px;
      background-color: base.$secondary-text;
      margin: 2rem 0;
    }

    .address {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin: 2rem 0;

      ul {
        display: flex;
        li {
          list-style: none;
          margin: 0 1rem;
        }
      }
    }

    .tel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      ul {
        display: flex;
        li {
          list-style: none;
          margin: 0 1rem;
        }
      }
    }
  }

  .footer__menu__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      height: 4rem;
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;

      img {
        height: 4rem;
      }
      li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 500;
        list-style: none;
        margin: 0 1rem;
        color: base.$secondary-text;

        .link {
          font-weight: 500;
          list-style: none;
          color: base.$secondary-text;
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: base.$secondary-text;
    background-color: base.$primary-bg;
    padding: 2rem;
    font-size: 0.9rem;

    .footer__menu {
      display: flex;
      flex-direction: column;
      span {
        width: 100%;
        height: 2px;
        background-color: base.$secondary-text;
        margin: 2rem 0;
      }

      .address {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 2rem 0;

        ul {
          display: flex;
          flex-direction: column;
          li {
            list-style: none;
            margin: 0.2rem 1rem;
          }
        }
      }

      .tel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ul {
          display: flex;
          flex-direction: column;
          li {
            list-style: none;
            margin: 0.2rem 1rem;
          }
        }
      }
    }

    .footer__menu__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 4rem;
      }

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;

        img {
          height: 4rem;
        }
        li {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-weight: 500;
          list-style: none;
          margin: 0 0.5rem;
          color: base.$secondary-text;

          .link {
            font-weight: 500;
            list-style: none;
            color: base.$secondary-text;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: base.$secondary-text;
    background-color: base.$primary-bg;
    padding: 2rem;
    font-size: 0.9rem;

    .footer__menu {
      display: flex;
      flex-direction: column;
      span {
        width: 100%;
        height: 2px;
        background-color: base.$secondary-text;
        margin: 2rem 0;
      }

      .address {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 2rem 0;

        ul {
          display: flex;
          flex-direction: column;
          li {
            list-style: none;
            margin: 0.2rem 1rem;
          }
        }
      }

      .tel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ul {
          display: flex;
          flex-direction: column;
          li {
            list-style: none;
            margin: 0.2rem 1rem;
          }
        }
      }
    }

    .footer__menu__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 4rem;
      }

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;

        img {
          height: 4rem;
        }
        li {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-weight: 500;
          list-style: none;
          margin: 0 0.5rem;
          color: base.$secondary-text;

          .link {
            font-weight: 500;
            list-style: none;
            color: base.$secondary-text;
          }
        }
      }
    }
  }
}
