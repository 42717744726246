@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

$primary-bg: #00587c;
$secondary-bg: rgb(255, 255, 255);

$primary-color: #00587c;
$secondary-color: #000000;

$primary-color-hover: #0178a7;

$primary-text: rgb(0, 0, 0);
$secondary-text: rgb(255, 255, 255);

$black: rgb(15, 15, 15);
$dark-grey: rgb(25, 25, 25);
$grey: rgb(100, 100, 100);
$lightgrey: rgb(220, 220, 220);

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  font-family: Roboto, Quicksand, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  list-style: none;

  display: flex;
  flex-direction: column;

  color: $primary-text;
  background-color: $primary-bg;

  &::-webkit-scrollbar {
    display: none;
  }
}

:root {
  --swiper-theme-color: #00374d;
}