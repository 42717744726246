@use "../../_base";

.Imprint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: base.$primary-text;
  background-color: base.$secondary-bg;

  .section__container {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 100vh;
    padding: 5rem 2rem;
    margin: auto;
  }

  h1 {
    color: base.$primary-color;
  }

  span {
    margin: 0.5rem 0;
  }

  &Bold {
    margin-top: 2rem !important;
    font-weight: bold;
    color: base.$primary-color;
  }
}

@media screen and (max-width: 500px) {
  .Imprint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: base.$primary-text;
    background-color: base.$secondary-bg;

    .section__container {
      display: flex;
      flex-direction: column;
      width: 60%;
      padding: 5rem 2rem;
      margin: auto;
    }

    h1 {
      color: base.$primary-color;
    }

    span {
      margin: 0.2rem 0;
    }

    &Bold {
      margin-top: 2rem !important;
      font-weight: bold;
      color: base.$primary-color;
    }
  }
}

@media screen and (max-width: 390px) {
  .Imprint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: base.$primary-text;
    background-color: base.$secondary-bg;

    .section__container {
      display: flex;
      flex-direction: column;
      width: 60%;
      padding: 5rem 2rem;
      margin: auto;
    }

    h1 {
      color: base.$primary-color;
    }

    span {
      margin: 0.2rem 0;
    }

    &Bold {
      margin-top: 2rem !important;
      font-weight: bold;
      color: base.$primary-color;
    }
  }
}
