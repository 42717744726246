@use "../../_base";

.Home {
  display: flex;
  flex-direction: column;
  .video__overlay {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 60vh;
    background-color: rgba($color: #000000, $alpha: 0.7);

    video {
      pointer-events: none;
    }
    .section__container {
      width: 40rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: auto 20vw;

      h1 {
        font-size: 3rem;
        margin-bottom: 1rem;
        color: base.$secondary-text;
      }
      span {
        font-size: 1.2rem;
        color: base.$secondary-text;
      }
      .button__container {
        display: flex;
        width: 100%;

        .link__container {
          display: flex;
          width: 100%;
          Button {
            width: 100%;
            font-size: 1rem;
            height: 3rem;
            margin-top: 2rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
  video {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 60vh;
    pointer-events: none;
  }

  .section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5rem 0;
    background-color: rgb(255, 255, 255);

    .sectionh1 {
      text-align: center;
    }

    h2 {
      color: base.$primary-color;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    h1 {
      color: base.$primary-color;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    .section1span {
      width: 50%;
      line-height: 2rem;
      text-align: center;
      color: base.$grey;
      margin-bottom: 2rem;
    }

    span {
      width: 85%;
      line-height: 2rem;
      text-align: center;
      color: base.$grey;
    }

    .leistungen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 2rem auto;
      padding: 5rem 0;
      width: 100%;
      background-color: base.$primary-color;

      h1 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;
        color: white;
      }

      .leystung__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: row;
        width: 90rem;
        height: 30rem;
        flex-direction: row;
        margin: 1rem;
      }

      .leystungSwiper {
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 1rem;

        .swiper-button-prev,
        .swiper-button-next {
          color: white;
        }
      }

      .leistungen__selection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 1rem 0;

        .leistungen__button {
          display: flex;
          align-items: center;
          justify-content: center;
          filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
          padding: 0.5rem 1rem;
          width: 25rem;
          border-radius: 5px;
          background-color: base.$primary-color;
          color: white;
          z-index: 2;
          font-size: 0.9rem;
          margin: 0.3rem;
          transition: 0.1s ease-out;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            background-color: base.$primary-color-hover;
            transform: scale(1.01);
          }
        }
      }

      .leistungen__container {
        display: flex;
        justify-content: center;
        width: 70%;
        height: 100%;
        padding: 1rem;
        margin: 1rem 0;
        text-align: justify;
        border: 3px solid base.$primary-color-hover;
        background-color: white;
        font-size: 1.1rem;
        color: #262626;
        border-radius: 10px;
        p {
          line-height: 1.5rem;
        }
      }
    }

    .why__section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 2rem;

      h2 {
        color: base.$primary-color;
      }

      .reason {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 75%;
        height: 5rem;
        background-color: base.$primary-color;
        border-radius: 5px;
        margin: 1rem 0;
        padding: 0.5rem 1rem;
        color: base.$secondary-text;
        filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
        transition: 0.1s ease-out;
        cursor: pointer;
        text-align: left;

        h3 {
          width: 15rem;
        }

        &:hover {
          background-color: base.$primary-color-hover;
          transform: scale(1.01);
        }

        span {
          color: base.$secondary-text;
        }
      }
    }
  }

  .leistungen__button.active {
    background-color: base.$primary-color-hover !important;
  }

  .contact__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 5rem;

    .contact__span {
      font-size: 1.2rem;
      color: base.$primary-color;
      padding: 2rem;
    }

    h1 {
      font-weight: bold;
      color: base.$primary-color;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1500px) {
  .Home {
    display: flex;
    flex-direction: column;
    .video__overlay {
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 60vh;
      background-color: rgba($color: #000000, $alpha: 0.7);

      video {
        pointer-events: none;
      }
      .section__container {
        width: 40rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto 20vw;

        h1 {
          font-size: 3rem;
          margin-bottom: 1rem;
          color: base.$secondary-text;
        }
        span {
          font-size: 1.2rem;
          color: base.$secondary-text;
        }
        .button__container {
          display: flex;
          width: 100%;

          .link__container {
            display: flex;
            width: 100%;
            Button {
              width: 100%;
              font-size: 1rem;
              height: 3rem;
              margin-top: 2rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }
    video {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 60vh;
      pointer-events: none;
    }

    .section1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 5rem 0;
      background-color: rgb(255, 255, 255);

      .sectionh1 {
        text-align: center;
      }

      h2 {
        color: base.$primary-color;
        font-weight: 400;
        margin-bottom: 2rem;
      }

      h1 {
        color: base.$primary-color;
        font-weight: 400;
        margin-bottom: 2rem;
      }

      .section1span {
        width: 50%;
        line-height: 2rem;
        text-align: center;
        color: base.$grey;
        margin-bottom: 2rem;
      }

      span {
        width: 85%;
        line-height: 2rem;
        text-align: center;
        color: base.$grey;
      }

      .leistungen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2rem auto;
        padding: 5rem 0;
        width: 100%;
        background-color: base.$primary-color;

        h1 {
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: bold;
          color: white;
        }

        .leystung__container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: row;
          width: 60rem;
          height: 30rem;
          flex-direction: row;
          margin: 1rem;
        }

        .leystungSwiper {
          display: none;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 1rem;

          .swiper-button-prev,
          .swiper-button-next {
            color: white;
          }
        }

        .leistungen__selection {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 1rem 0;

          .leistungen__button {
            display: flex;
            align-items: center;
            justify-content: center;
            filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
            padding: 0.5rem 1rem;
            width: 25rem;
            border-radius: 5px;
            background-color: base.$primary-color;
            color: white;
            z-index: 2;
            font-size: 0.9rem;
            margin: 0.3rem;
            transition: 0.1s ease-out;
            font-weight: 500;
            cursor: pointer;

            &:hover {
              background-color: base.$primary-color-hover;
              transform: scale(1.01);
            }
          }
        }

        .leistungen__container {
          display: flex;
          justify-content: center;
          width: 70%;
          height: 100%;
          padding: 1rem;
          margin: 1rem 0;
          text-align: justify;
          border: 3px solid base.$primary-color-hover;
          background-color: white;
          font-size: 1.1rem;
          color: #262626;
          border-radius: 10px;
          p {
            line-height: 1.5rem;
          }
        }
      }

      .why__section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 2rem;

        h2 {
          color: base.$primary-color;
        }

        .reason {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          width: 75%;
          background-color: base.$primary-color;
          border-radius: 5px;
          margin: 1rem 0;
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          height: 4rem;
          color: base.$secondary-text;
          filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
          transition: 0.1s ease-out;
          cursor: pointer;
          text-align: left;

          h3 {
            width: 15rem;
          }

          &:hover {
            background-color: base.$primary-color-hover;
            transform: scale(1.01);
          }

          span {
            color: base.$secondary-text;
          }
        }
      }
    }

    .leistungen__button.active {
      background-color: base.$primary-color-hover !important;
    }

    .contact__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 5rem;

      .contact__span {
        font-size: 1.2rem;
        color: base.$primary-color;
        padding: 2rem;
      }

      h1 {
        font-weight: bold;
        color: base.$primary-color;
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .Home {
    display: flex;
    flex-direction: column;
    .video__overlay {
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 60vh;
      background-color: rgba($color: #000000, $alpha: 0.7);

      video {
        pointer-events: none;
      }
      .section__container {
        width: 30rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto 10vw;

        h1 {
          font-size: 2.5rem;
          margin-bottom: 1rem;
          color: base.$secondary-text;
        }
        span {
          font-size: 1.2rem;
          color: base.$secondary-text;
        }
        .button__container {
          display: flex;
          width: 100%;

          .link__container {
            display: flex;
            width: 100%;
            Button {
              width: 100%;
              font-size: 1rem;
              height: 3rem;
              margin-top: 2rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }
    video {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 60vh;
      pointer-events: none;
    }

    .section1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 3rem 0rem;
      background-color: rgb(255, 255, 255);

      .sectionh1 {
        text-align: center;
        padding: 0 1rem;
      }

      h2 {
        color: base.$primary-color;
        font-weight: 400;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }

      h1 {
        color: base.$primary-color;
        font-weight: 400;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }

      .section1span {
        width: 100%;
        line-height: 2rem;
        padding: 0 1rem;
        text-align: center;
        color: base.$grey;
        margin-bottom: 2rem;
      }

      span {
        width: 85%;
        line-height: 2rem;
        text-align: center;
        color: base.$grey;
      }

      .leistungen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2rem auto;
        padding: 4rem 0;
        width: 100%;
        background-color: base.$primary-color;

        .leystungSwiper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 1rem;

          .swiper-button-prev,
          .swiper-button-next {
            color: white;
          }
        }

        h1 {
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: bold;
          color: white;
          text-align: center;
          margin: 0;
        }

        .leystung__container {
          display: none;
          justify-content: space-between;
          align-items: center;
          flex: row;
          width: 100%;
          height: 30rem;
          flex-direction: row;
          margin: 1rem;
        }
      }

      .why__section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding: 2rem 0;

        h2 {
          color: base.$primary-color;
        }

        .reason {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          width: 90%;
          height: 4rem;
          font-size: 0.9rem;
          background-color: base.$primary-color;
          border-radius: 5px;
          margin: 1rem 0;
          padding: 0.5rem 1rem;
          color: base.$secondary-text;
          filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
          transition: 0.1s ease-out;
          cursor: pointer;
          text-align: left;

          h3 {
            width: 15rem;
          }

          &:hover {
            background-color: base.$primary-color-hover;
            transform: scale(1.01);
          }

          span {
            margin: 0;
            line-height: 1rem;
            color: base.$secondary-text;
          }
        }
      }
    }

    .leistungen__button.active {
      background-color: base.$primary-color-hover !important;
    }

    .contact__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0rem 1rem;

      .contact__span {
        font-size: 1.2rem;
        color: base.$primary-color;
        padding: 0rem;
      }

      h1 {
        font-weight: bold;
        color: base.$primary-color;
        margin: 2rem 0;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .Home {
    display: flex;
    flex-direction: column;
    .video__overlay {
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 60vh;
      background-color: rgba($color: #000000, $alpha: 0.7);

      video {
        pointer-events: none;
      }
      .section__container {
        width: 30rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto 10vw;

        h1 {
          font-size: 2.5rem;
          margin-bottom: 1rem;
          color: base.$secondary-text;
        }
        span {
          font-size: 1.2rem;
          color: base.$secondary-text;
        }
        .button__container {
          display: flex;
          width: 100%;

          .link__container {
            display: flex;
            width: 100%;
            Button {
              width: 100%;
              font-size: 1rem;
              height: 3rem;
              margin-top: 2rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }
    video {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 60vh;
      pointer-events: none;
    }

    .section1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 3rem 0rem;
      background-color: rgb(255, 255, 255);

      .sectionh1 {
        text-align: center;
        padding: 0 1rem;
      }

      h2 {
        color: base.$primary-color;
        font-weight: 400;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }

      h1 {
        color: base.$primary-color;
        font-weight: 400;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }

      .section1span {
        width: 100%;
        line-height: 2rem;
        padding: 0 1rem;
        text-align: center;
        color: base.$grey;
        margin-bottom: 2rem;
      }

      span {
        width: 85%;
        line-height: 2rem;
        text-align: center;
        color: base.$grey;
      }

      .leistungen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2rem auto;
        padding: 4rem 0;
        width: 100%;
        background-color: base.$primary-color;

        .leystungSwiper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 1rem;

          .swiper-button-prev,
          .swiper-button-next {
            color: white;
          }
        }

        h1 {
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: bold;
          color: white;
          text-align: center;
          margin: 0;
        }

        .leystung__container {
          display: none;
          justify-content: space-between;
          align-items: center;
          flex: row;
          width: 100%;
          height: 30rem;
          flex-direction: row;
          margin: 1rem;
        }
      }

      .why__section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding: 2rem 0;

        h2 {
          color: base.$primary-color;
        }

        .reason {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          width: 90%;
          height: 5rem;
          font-size: 0.7rem;
          background-color: base.$primary-color;
          border-radius: 5px;
          margin: 1rem 0;
          padding: 0.5rem 1rem;
          color: base.$secondary-text;
          filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.5));
          transition: 0.1s ease-out;
          cursor: pointer;
          text-align: left;

          h3 {
            width: 15rem;
          }

          &:hover {
            background-color: base.$primary-color-hover;
            transform: scale(1.01);
          }

          span {
            margin: 0;
            line-height: 1rem;
            color: base.$secondary-text;
          }
        }
      }
    }

    .leistungen__button.active {
      background-color: base.$primary-color-hover !important;
    }

    .contact__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0rem 1rem;

      .contact__span {
        font-size: 1.2rem;
        color: base.$primary-color;
        padding: 0rem;
      }

      h1 {
        font-weight: bold;
        color: base.$primary-color;
        margin: 2rem 0;
        padding: 0;
      }
    }
  }
}
