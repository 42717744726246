@use "../../_base";

.Input {
    display: flex;
    border: 2px solid base.$primary-color;
    transition: border ease 0.2s;
    padding: 0.7rem;
    font-size: 1rem;
    border-radius: 5px;
    outline: none;

    &:focus {
        border: 2px solid base.$primary-color-hover;
    }
}