@use "../../_base";

.ContactPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: base.$primary-text;
  background-color: base.$secondary-bg;

  .section__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: 5rem 0;
    margin: auto;

    .form {
      textarea {
        height: 40vh;
      }
    }

    h1 {
      color: base.$primary-color;
      margin-bottom: 2rem;
    }

    span {
      margin-bottom: 2rem;
      line-height: 1.25rem;
      text-align: justify;
      color: rgb(50, 50, 50);
    }

    h2 {
      background-color: base.$primary-color;
      color: white;
      padding: 0.5rem 1rem;
      margin-bottom: 5rem;
      border-radius: 10px;

      .callLink {
        color: white;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .ContactPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: base.$primary-text;
    background-color: base.$secondary-bg;

    .section__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      min-width: 80%;
      padding: 5rem 0;
      margin: auto;

      .form {
        textarea {
          height: 40vh;
        }
      }

      h1 {
        color: base.$primary-color;
        margin-bottom: 2rem;
      }

      span {
        margin-bottom: 2rem;
        line-height: 1.25rem;
        text-align: left;
        color: rgb(50, 50, 50);
      }

      h2 {
        background-color: base.$primary-color;
        color: white;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin-bottom: 5rem;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .ContactPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: base.$primary-text;
    background-color: base.$secondary-bg;

    .section__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      min-width: 80%;
      padding: 5rem 0;
      margin: auto;

      .form {
        textarea {
          height: 40vh;
        }
      }

      h1 {
        color: base.$primary-color;
        margin-bottom: 2rem;
      }

      span {
        margin-bottom: 2rem;
        line-height: 1.25rem;
        text-align: left;
        color: rgb(50, 50, 50);
      }

      h2 {
        background-color: base.$primary-color;
        color: white;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin-bottom: 5rem;
        border-radius: 10px;
      }
    }
  }
}
