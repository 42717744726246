@use "../../_base";

.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  Button {
    width: 100%;
    font-size: 1rem;
  }

  Input {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .ReCAPTCHA {
    margin: 1rem 0;
  }

  textarea {
    font-size: 1rem;
    width: 100%;
    min-height: 20vh;
    display: flex;
    border: 2px solid base.$primary-color;
    padding: 0.7rem;
    border-radius: 5px;
    outline: none;
    transition: border ease 0.2s;
    resize: none;

    &:focus {
      border: 2px solid base.$primary-color-hover;
    }
  }
}

@media screen and (max-width: 390px) {
  .Contact {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    Button {
      font-size: 1rem;
    }
  
    Input {
      margin-bottom: 1.5rem;
    }
  
    textarea {
      font-size: 1rem;
      min-height: 20vh;
      display: flex;
      border: 2px solid base.$primary-color;
      padding: 0.7rem;
      border-radius: 5px;
      outline: none;
      transition: border ease 0.2s;
      resize: none;
      margin-bottom: 1.5rem;
  
      &:focus {
        border: 2px solid base.$primary-color-hover;
      }
    }
  }
 }

 @media screen and (max-width: 500px) {
  .Contact {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    Button {
      font-size: 1rem;
    }
  
    Input {
      margin-bottom: 1.5rem;
    }
  
    textarea {
      font-size: 1rem;
      min-height: 20vh;
      display: flex;
      border: 2px solid base.$primary-color;
      padding: 0.7rem;
      border-radius: 5px;
      outline: none;
      transition: border ease 0.2s;
      resize: none;
      margin-bottom: 1.5rem;
  
      &:focus {
        border: 2px solid base.$primary-color-hover;
      }
    }
  }
 }